/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

button:focus, button:active:focus, button.active:focus {
    outline: none !important;
    outline-style: none !important;
}

.persona-button:hover {
    cursor: pointer;
}

.nav-item {
    margin-left: 15px;
}

.downloadTable {
    border-collapse: collapse;
    border: 1px solid rgb(200, 200, 200);
}

.downloadTable thead, tfoot {
}

.downloadTable tbody {
}

.downloadTable td, th {
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;
}

.downloadTable td {
}